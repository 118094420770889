// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import moment from 'moment'

export const layoutSlice = createSlice({
  name: 'licitapro',
  initialState: {
    productos: null,
    licitaciones: null,
    usuarios: null,
    seguimiento: { desde: moment().subtract(1, 'month').toDate(), hasta: null }
  },
  reducers: {
    handleProductos: (state, action) => {
      state.productos = action.payload
    },
    handleUsuarios: (state, action) => {
      state.usuarios = action.payload
    },
    handleLicitaciones: (state, action) => {
      state.licitaciones = action.payload
    },
    handleSeguimiento: (state, action) => {
      const { desde, hasta, listado } = action.payload
      state.seguimiento = { desde, hasta, listado }
    },
    handleTag: (state, action) => {

      const { tags, id } = action.payload

      axios.post(`/licitaciones/editar/${id}/tag`, tags.split(",").map(e => e * 1))
        .catch(e => console.error(e))

      if (state.licitaciones) {
        const licitaciones = [...state.licitaciones]
        const i = licitaciones.findIndex(e => e.id === id)
        licitaciones[i].tags = tags
        state.licitaciones = licitaciones
      }
      //buscar en otros arrays acá
    },
    handleAsignado: (state, action) => {

      const { asignado, id } = action.payload

      axios.post(`/licitaciones/editar/${id}/asignar`, { Usuario: asignado })
        .catch(e => console.error(e))

      if (state.licitaciones) {
        const licitaciones = [...state.licitaciones]
        const i = licitaciones.findIndex(e => e.id === id)
        licitaciones[i].Asignado = asignado
        state.licitaciones = licitaciones
      }
      //buscar en otros arrays acá
    }
  }
})

export const {
  handleProductos,
  handleLicitaciones,
  handleTag,
  handleAsignado,
  handleSeguimiento,
  handleUsuarios
} = layoutSlice.actions

export default layoutSlice.reducer
