import mock from '../mock'

const data = {
  pricing: {
    basicPlan: {
      title: '3 meses',
      duracion: 3,
      img: require('@src/assets/images/illustration/Pot1.svg').default,
      monthlyPrice: 0,
      yearlyPlan: {
        perMonth: 33330,
        totalAnnual: 99990
      },
      planBenefits: [
        'Hasta 10 usuarios',
        'Almacena archivos hasta 300Mb'
      ],
      popular: false
    },
    standardPlan: {
      title: '6 meses',
      duracion: 6,
      img: require('@src/assets/images/illustration/Pot2.svg').default,
      monthlyPrice: 49,
      yearlyPlan: {
        perMonth: 30000,
        totalAnnual: 179990
      },
      planBenefits: [
        'Hasta 20 usuarios',
        'Almacena archivos hasta 600Mb'
      ],
      popular: false
    },
    enterprisePlan: {
      title: '12 meses',
      duracion: 12,
      img: require('@src/assets/images/illustration/Pot3.svg').default,
      monthlyPrice: 99,
      yearlyPlan: {
        perMonth: 25000,
        totalAnnual: 299990
      },
      planBenefits: [
        'Usuarios ilimitados',
        'Almacena archivos hasta 1Gb'
      ],
      popular: true
    },
    customPlan: {
      title: 'Empresarial',
      img: require('@src/assets/images/illustration/faq-illustrations.svg').default,
      monthlyPrice: 99,
      yearlyPlan: {
        perMonth: '',
        totalAnnual: 'Contacto'
      },
      planBenefits: [
        'Usuarios ilimitados',
        'Espacio de almacenamiento personalizado',
        'Reportes Empresariales'
      ],
      popular: false
    }
  }
}

mock.onGet('/pricing/data').reply(() => [200, data.pricing])
