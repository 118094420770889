import Cookies from 'universal-cookie'

const cookies = new Cookies()
// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

import * as pbi from 'powerbi-client';

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: {},
    empresa: 0,
    powerbi: new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory),
    pbiloaded: false
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      state.empresa = cookies.get("empresa") * 1
    },
    handleLogout: state => {
      try { cookies.remove('LaGalleta', { path: '/' }) } catch{ }
      try { cookies.remove('empresa', { path: '/' }) } catch{ }
      state.userData = {}
    },
    handlePBI: state=>{
      state.pbiloaded = true
    }
  }
})

export const { handleLogin, handleLogout, handlePBI } = authSlice.actions

export default authSlice.reducer
